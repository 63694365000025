<template>
  <div class="group-dialog modal__detail--rule">
    <b-modal id="modalDetailRule"
      centered hide-footer
      size="lg"
      no-duplicateRule-on-esc
      no-duplicateRule-on-backdrop
      title="Detallado de Regla">
      <div >
        <b-list-group>
          <b-list-group-item class="card__detail--item" v-if="!loading">
            <b-container>
              <b-row class="card__detail--row">
                <b-col class="card__detail--col">
                  <p class="mb-0 small text-muted">Nombre regla</p>
                  <p class="card__detail--rule">{{dataDetail.name}}</p>
                </b-col>
                <b-col class="card__detail--col">
                  <p class="mb-0 small text-muted">Nuevo estado de lectura</p>
                  <p class="card__detail--rule">{{dataDetail.readAgent}}</p>
                </b-col>
                <b-col class="card__detail--col">
                  <p class="mb-0 small text-muted">Nuevo estado de ticket</p>
                  <p class="card__detail--rule">{{dataDetail.ticketSubStatusText}}</p>
                </b-col>
                <b-col class="card__detail--col">
                  <p class="mb-0 small text-muted">Nuevo asunto de ticket</p>
                  <p class="card__detail--rule">{{dataDetail.ticketSubjectText}}</p>
                </b-col>
                <b-col class="card__detail--col">
                  <p class="mb-0 small text-muted">Nombre Canal de Slack</p>
                  <p class="card__detail--rule">{{dataDetail.slack_channel}}</p>
                </b-col>
              </b-row>
            </b-container>
          </b-list-group-item>
          <b-list-group-item class="card__detail--item" v-if="dataDetail.message && !loading">
            <p class="mb-0 small text-muted">Mensaje</p>
            <p class="card__detail--rule">{{dataDetail.message}}</p>
          </b-list-group-item>
          <!-- <b-list-group-item class="card__detail--item" v-if="dataDetail.serviceModes && !loading">
            <p class="mb-0 small text-muted">Modos de servicio</p>
            <p class="card__detail--rule card__detail--rule-margin"  v-for="service of dataDetail.serviceModes" :key="service" >{{service.text}}</p>
          </b-list-group-item> -->
          <b-list-group-item class="card__detail--item" v-if="viewSpecialRule && !loading">
            <p class="card__detail--rule card__detail--rule-margin" v-show="dataDetail.superRule">Regla prioritaria al ejecutar procesos automáticos</p>
            <p class="card__detail--rule card__detail--rule-margin" v-show="dataDetail.reportRule">Reportar al courier</p>
            <p class="card__detail--rule card__detail--rule-margin" v-show="dataDetail.ticketOpening">Apertura de ticket</p>
            <p class="card__detail--rule card__detail--rule-margin" v-show="dataDetail.is_notification">Es Notificación</p>
            <p class="card__detail--rule card__detail--rule-margin" v-show="dataDetail.excel_email">La regla puede ser creadas como reporte de excel.</p>
          </b-list-group-item>
          <b-list-group-item class="card__detail--item" v-if="!loading && dataDetail && dataDetail.programedRule && dataDetail.programedRule.length > 0 ">
            <p class="mb-0 small text-muted">Regla programada</p>
            <div v-for="(programedRule, index) in dataDetail.programedRule" :key="index">
              <p>{{setCountry(programedRule.country)}}
                <span class="card__detail--rule card__detail--rule-margin" v-for="(days, index) in programedRule.frequencies" :key="index">
                  {{setDay(days.day)}}: {{days.time}}
                </span>
              </p>
            </div>
          </b-list-group-item>
          <b-list-group-item class="card__detail--item" v-show="!loading">
            <p class="mb-0 small text-muted">Lista de condiciones</p>
            <conditions-list :conditions.sync="conditions" :editable="false" :ready="!loading" />
          </b-list-group-item>
          <b-list-group-item v-if="conditionsBack.length > 0">
            <p class="mb-0 small text-muted">Lista de condiciones solo para back</p>
            <p v-for="(condition, index) in conditionsBack" :key="index">
            <b>variable:</b> {{condition.variable}}, <b>operador:</b> {{ condition.operator }} <b>valores:</b> {{ condition.values }}
            </p>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div v-show="loading" class="skeleton-container">
        <b-list-group>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
          <b-list-group-item><b-skeleton height="100px"></b-skeleton></b-list-group-item>
        </b-list-group>
      </div>
      <div class="card__detail--footer">
        <b-button variant="danger" class="card__detail--rule-margin" @click="duplicateRule">Duplicar</b-button>
        <b-button v-if="!dataDetail.readOnly" variant="success" class="card__detail--rule-margin" @click="editRule">Editar</b-button>
        <b-button  variant="warning" class="card__detail--rule-margin" @click="hideRule">Cerrar</b-button>
      </div>

    </b-modal>
  </div>
</template>
<script>
import TicketManagementService from '../../ticketManagement.service'
import comunas from '@/const-data/comunas.json'
// import cards from '@/const-data/groups/cards'
import operators from '@/const-data/groups/operators'
import lastUserList from '@/const-data/groups/last_user'
import { BContainer, BRow, BCol } from 'bootstrap-vue'
import ConditionsList from '../create/ConditionsList.vue'
import { environment } from '@/environments/environment'
const ticketManagementService = new TicketManagementService()
export default {
  name: 'modal-detail-rule',
  components: {ConditionsList, BContainer, BRow, BCol},
  props: ['cards', 'countries', 'fxArray'],
  data () {
    return {
      dataModalDetail: {},
      dataDetail: {},
      readStatus: [{id:'', text: 'Mantener estado de lectura'}, {id: 1, text: 'Leído'}, {id: 2, text: 'No leído'}],
      optionsServiceModes: [
        { id: 'DIS', text: 'Distribución'},
        { id: 'INV', text: 'Logística inversa (Devolución)'},
        { id: 'PAS', text: 'Pasarela'}
      ],
      viewSpecialRule: false,
      days: [
        { id: 0, text: 'Domingo'},
        { id: 1, text: 'Lunes'},
        { id: 2, text: 'Martes'},
        { id: 3, text: 'Miércoles'},
        { id: 4, text: 'Jueves'},
        { id: 5, text: 'Viernes'},
        { id: 6, text: 'Sábado'}
      ],
      conditionsLoaded: 0,
      conditions: [],
      conditionsBack: [],
      loading: true
    }
  },
  watch: {
    dataModalDetail: {
      handler: 'setData'
    }
  },
  methods: {
    setData () {
      this.setLoading(true)
      this.dataDetail = {
        id: this.dataModalDetail.id,
        name: this.dataModalDetail.name,
        readAgent: this.dataModalDetail.read_agent ? this.readStatus[this.dataModalDetail.read_agent ? 1 : 2].text : this.readStatus[0].text,
        ticketStatusText: this.dataModalDetail.ticket_status_text ? this.dataModalDetail.ticket_status_text : 'Mantener estado',
        ticketSubStatusText: this.dataModalDetail.ticket_sub_status_text ? this.dataModalDetail.ticket_sub_status_text : 'Mantener estado',
        ticketSubjectText: this.dataModalDetail.ticket_subject_text ? this.dataModalDetail.ticket_subject_text : 'Mantener asunto',
        message: this.dataModalDetail.message ? this.dataModalDetail.message : 'Sin Mensaje',
        // serviceModes: this.dataModalDetail.service_modes ? this.optionsServiceModes.filter(el => this.dataModalDetail.service_modes.includes(el.id)) : [],
        superRule: !!this.dataModalDetail.super,
        excelEmail: !!this.dataModalDetail.excel_email,
        reportRule: !!this.dataModalDetail.report_to_carrier,
        ticketOpening: !!this.dataModalDetail.ticket_opening,
        programedRule: !!this.dataModalDetail.execution_frequency && this.dataModalDetail.execution_frequency.length ? this.dataModalDetail.execution_frequency : [],
        conditions: [],
        readOnly: this.dataModalDetail.read_only === 1,
        is_notification: !!this.dataModalDetail.is_notification || this.dataModalDetail.is_notification === 1,
        slack_channel: this.dataModalDetail.slack_channel
      }
      this.viewSpecialRule = this.dataDetail.superRule || this.dataDetail.reportRule || this.dataDetail.ticketOpening || this.dataDetail.is_notification || this.dataDetail.excel_email
      this.setConditions(this.dataModalDetail.conditions)
    },
    duplicateRule (e) {
      e.preventDefault()
      const params = {
        id: this.dataDetail.id
      }
      this.setLoading(true)
      ticketManagementService.callService('duplicateRule', {}, {id: this.dataDetail.id}, localStorage.getItem('currentToken'))
        .then(response => {
          const newId = response.data.id
          if (newId) {
            this.m_openWinBlank(`/detail-ticket-rules/${response.data.id}`)
            this.$emit('duplicate')
            this.$bvModal.hide('modalDetailRule')
          }
        })
        .catch(err => {
          this.$alert('Ocurrió un problema al duplicar la regla')
          console.error(err)
        })
        .finally(() => {
          this.setLoading(false)
          this.$bvModal.hide('modalDetailRule')
        })
    },
    editRule (e) {
      e.preventDefault()
      this.$bvModal.hide('modalDetailRule')
      this.m_openWinBlank(`/detail-ticket-rules/${this.dataDetail.id}`)      
    },
    getTextValues (condition) {
      if (!!condition.variable.fx) {
        return this.getListGroup(condition)
      } else {
        condition.valueGroup.text_values = condition.valueGroup.values
        this.conditionsLoaded++
        if (this.conditionsLoaded === this.conditions.length) this.setLoading(false)
        return condition
      }
    },
    getListGroup (condition) {
      if (!!condition.variable.fx) {
        this.setLoading(true)
        const listValues = ticketManagementService.formatSelect(this.fxArray[condition.variable.fx], condition.variable.id)
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
        this.conditionsLoaded++
        if (this.conditionsLoaded === this.conditions.length) this.setLoading(false)
        this.setLoading(false)
        return condition
      } else if (condition.variable.data) {
        const listValues = condition.variable.data
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      } else if (['commune', 'commune_drop'].includes(condition.variable.id)) {
        const listValues = ticketManagementService.formatSelect(comunas, condition.variable.id)
        condition.valueGroup.text_values = condition.valueGroup.values.map(val => {
          const indexElement = listValues.findIndex(element => element.id === val)
          return indexElement !== -1 ? listValues[indexElement].text : val
        })
      }
    },
    hideRule (e) {
      e.preventDefault()
      this.$bvModal.hide('modalDetailRule')
    },
    setConditions (conditions) {
      this.conditionsBack = []
      const listConditions = []
      if (conditions.length > 0) {
        conditions.map(condition => {
          const variable = this.cards.filter(({id}) => id === condition.variable)[0]
          const operator = operators.filter(({id}) => id === condition.operator)[0]
          const last_user = lastUserList.filter(({id}) => id === condition.last_user)[0]
          if (variable && operator) {
            listConditions.push({
              variable: variable || null,
              operator: {...operator, textOperator: ticketManagementService.getTextOperator(operator.id)} || null,
              last_user:  {...last_user, textLastUser: (last_user && last_user.id !== '' ? last_user.text.toLowerCase() : '')},
              group: { id: '', text: '--', values: condition.values },
              valueGroup: {id: -1, values: condition.values, typeof_value: ticketManagementService.validateNullVariable({type: variable.type, values: condition.values}), variable: variable.id}
            })
          } else {
            this.conditionsBack = [...this.conditionsBack, condition]
          }
        })
        listConditions.map(condition => {
          condition = {...this.getTextValues(condition)}
          return condition
        })
        this.conditions = [...listConditions]
        this.setLoading(false)
      } else {
        this.setLoading(false)
      }
    },
    setCountry (codCountry) {
      return this.countries.filter(el => codCountry === el.code)[0].name
    },
    setDay (idDay) {
      return this.days.filter(el => idDay === el.id)[0].text
    },
    setLoading (bool) {
      if (bool) this.loading = true
      else setTimeout(() => this.loading = false, 500)
    }
  }
}
</script>
<style lang="scss">
  .card__detail--rule {
    border: 1px solid #d4d4d4;
    background: #f3f3f3;
    padding: 2px 5px;
    margin-bottom: 0;
    border-radius: 5px;
    color: gray;
    display:inline-block;
  }
  .card__detail--rule-margin {
    margin-right: 10px;
  }
  .card__detail--item {
    padding: 0.5rem 1rem;
  }
  .card__detail--col {
    padding: 0;
  }
  .card__detail--row {
    gap: 1rem;
  }
  .card__detail--footer {
    text-align: right;
    margin-top: 10px;
  }
</style>
