<template>
  <div>
    <form-render ref="exportRulesRef" :fields="fieldBase" :form.sync="form" class="justify-content-end" v-on:send="filterRules" :containerButtonsClass="'mt-2'" :key="keyFormRender">
      <template #advanceFilter>
        <b-button variant="link" class="m-0" v-b-modal.advancedFilterRulesId>
          Búsqueda Avanzada
        </b-button>
      </template>
      <template #resetFilterSlot>
        <div class="mt-2 flex justify-content-between">
          <b-button variant="outline-info" @click="resetFilter" class="m-0"><feather-icon icon="RefreshCwIcon"/></b-button>
          <b-button variant="success" type="submit" class="m-0">Filtrar</b-button>
          <b-button variant="outline-success" class="m-0" @click="getRules">Exportar</b-button>
        </div>
      </template>
    </form-render>
    <b-modal id="advancedFilterRulesId" centered size="xl" title="Búsqueda Avanzada">
      <form-render formId="advancedForm" :fields="fieldAdvanced" :form.sync="form" class="justify-content-end" v-on:send="filterRules" :key="keyFormRender">
        <template #conditionSlot>
          <div class="d-flex justify-content-between">
            <span class="font-weight-bolder mr-1">Condiciones</span>
            <b-button variant="link" :disabled="disableAddCondition" class="p-0 m-0" @click="addCondition">Agregar Condición</b-button>
          </div>
        </template>
      </form-render>
      <template #modal-footer>
        <div class="flex justify-content-between">
          <b-button variant="outline-info" @click="resetFilter" class="m-0 mr-1"><feather-icon icon="RefreshCwIcon"/></b-button>
          <b-button variant="success" type="submit" form="advancedForm" class="m-0">Filtrar</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import TicketManagementService from '../../ticketManagement.service'
const ticketManagementService = new TicketManagementService()
export default {
  name: 'ExportRules',
  props: ['countries', 'conditions'],
  data() {
    return {
      fieldBase: [],
      fieldAdvanced: [],
      form: {},
      optionsContract: [
        { id: 'PAS', text: 'Pasarela' },
        { id: 'DIS', text: 'Distribución' },
        { id: 'INV', text: 'Logística inversa' }
      ],
      optionsRule: [
        { id: 'automatic', text: 'Reglas automáticas' },
        { id: 'scheduled', text: 'Reglas programadas' }
      ],
      slots: [
        { name: 'advanceFilter', containerClass: 'container-info vx-col w-full col-3 justify-content-end', useSlot: true },
        { name: 'resetFilterSlot', containerClass: 'container-info vx-col w-full col-3', useSlot: true },
        { name: 'conditionSlot', label: '', containerClass: 'container-info col-12', useSlot: true }
      ],
      buttonSend: {
        title: 'Filtrar', icon: 'file-excel', color: 'success', text: 'Filtrar'
      },
      keyCondition: 1,
      keyFormRender: 0
    }
  },
  computed: {
    countryOptions() {
      return this.countries.map(el => ({ ...el, id: el.code, text: el.name }))
    },
    conditionOptions() {
      return this.conditions.map(el => ({ ...el, text: el.title })).filter(el => el.id !== 'tickets')
    },
    conditionObject() {
      return {
        fieldType: 'FieldSelect',
        label: 'Variable',
        containerClass: 'container-info vx-col w-full col-7',
        change: this.changeCondition,
        options: this.conditionOptions,
        name: `condition_${this.keyCondition}`
      }
    },
    disableAddCondition() {
      return false
    },
    fields() {
      return [
        { fieldType: 'FieldSelect', name: 'countries', label: 'País', containerClass: 'container-info vx-col w-full col-3', options: this.countryOptions, searchOnType: { nChars: 999 }, addFlags: true },
        { fieldType: 'FieldSelect', name: 'rule_type', label: 'Tipos de reglas', containerClass: 'container-info vx-col w-full col-3', options: this.optionsRule, searchOnType: { nChars: 999 } },
        { fieldType: 'FieldSelect', name: 'contract_type', label: 'Tipo de contrato', containerClass: 'container-info vx-col w-full col-3', options: this.optionsContract, searchOnType: { nChars: 999 } },
        { fieldType: 'FieldDatepicker', range: true, label: 'Rango de fechas', name: 'dateRange', containerClass: 'container-info vx-col w-full col-5', clearable: true, mode: 'dateTime', searchOnType: { nChars: 999 } }
      ]
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    addCondition() {
      this.keyCondition++
      this.fieldAdvanced.push({ ...this.conditionObject })
      this.keyFormRender++
    },
    changeCondition(data) {
      const numberCondition = data.split('_')[1]
      const condition = this.form[data]
      this.removeValueField(numberCondition)
      if (condition) this.setDefaultValues(condition, numberCondition, data)
      this.keyFormRender++
    },
    filterRules() {
      this.$emit('filterRules', this.setQueryParams())
      this.$bvModal.hide('advancedFilterRulesId')
    },
    getFieldType(type) {
      return type === 'list' ? 'FieldSelect' : 'FieldInput'
    },
    getRules() {
      const queryParams = this.setQueryParams()
      this.filterRules()
      ticketManagementService.callService('getExportRule', queryParams, {}, localStorage.getItem('currentToken'))
        .then(response => window.open(`${response.data[0]}`, '_blank'))
        .catch(err => {
          console.error(err)
          this.$alert('No se pudieron exportar las reglas')
        })
    },
    removeValueField(conditionNumber) {
      const index = this.fieldAdvanced.map(el => el.name).indexOf(`value_condition_${conditionNumber}`)
      delete this.form[`value_condition_${conditionNumber}`]
      if (index !== -1) this.fieldAdvanced.splice(index, 1)
    },
    resetFilter() {
      this.form = {}
      this.setFieldsAdvanced()
      this.filterRules()
    },
    setData() {
      this.fieldBase = [
        { ...this.fields[0], options: this.countryOptions },
        { ...this.slots[0] },
        { ...this.slots[1] }
      ]
      this.setFieldsAdvanced()
    },
    setFieldsAdvanced() {
      this.fieldAdvanced = [
        { ...this.fields[0], options: this.countryOptions, containerClass: 'container-info vx-col w-full col-12' },
        { ...this.fields[1], containerClass: 'container-info vx-col w-full col-12' },
        { ...this.fields[2], containerClass: 'container-info vx-col w-full col-12' },
        { ...this.slots[2] },
        { ...this.conditionObject }
      ]
    },
    async setDefaultValues(condition, numberCondition, data) {
      const indexCondition = this.fieldAdvanced.map(el => el.name).indexOf(data)
      let object = {
        ...condition,
        fieldType: this.getFieldType(condition.type),
        name: `value_condition_${numberCondition}`,
        label: 'Value',
        type: condition.type === 'number' ? 'number' : null,
        containerClass: 'container-info vx-col w-full col-5'
      }
      try {
        if (condition.id === 'country') {
          object = { ...object, options: this.countryOptions, addFlags: true }
        } else if (condition.fx !== '') {
          const response = await ticketManagementService.callService(condition.fx, { limit: 9999, page: 1 })
          object = { ...object, options: ticketManagementService.formatSelect(response.data, condition.id) }
        } else if (condition.data && condition.data.length > 0) {
          object = { ...object, options: condition.data }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.fieldAdvanced.splice(indexCondition + 1, 0, object)
      }
    },
    setQueryParams() {
      const queryParams = { conditions: '' }
      if (!!this.form) {
        for (const key in this.form) {
          if (Object.hasOwnProperty.call(this.form, key)) {
            const conditionKey = key.startsWith('value_condition_') || key.startsWith('condition_')
            if (conditionKey) queryParams.conditions += this.arrangementCondition(key)
            else queryParams[key] = this.form[key].id
          }
        }
        if (queryParams.conditions.length > 0) queryParams.conditions = this.removeCharacter(queryParams.conditions)
      }
      return queryParams
    },
    removeCharacter(conditions) {
      return conditions.replace(/,+$/, '')
    },
    arrangementCondition(key) {
      let stringCondition = ''
      const conditionCheck = key.startsWith('condition_')
      const conditionValueCheck = this.form[key]
      if (conditionCheck && conditionValueCheck) {
        const numberCondition = key.split('_')[1]
        const conditionValue = this.form[`value_condition_${numberCondition}`]
        const keyId = this.form[key].id
        if (Array.isArray(conditionValue)) {
          conditionValue.forEach(element => {
            stringCondition += keyId
            stringCondition += ','
            stringCondition += element.id ?? ''
            stringCondition += ','
          })
        } else {
          stringCondition += keyId
          stringCondition += ','
          stringCondition += conditionValue && conditionValue.id ? conditionValue.id : conditionValue ? conditionValue : ''
          stringCondition += ','
        }
      }
      return stringCondition
    }
  }
}
</script>
