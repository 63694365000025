<template>
  <div class="rule-list" v-if="viewData">
    <b-card>
      <div class="row mb-6">

        <div class="w-full col-sm-12">
          <filter-rules :countries="countries" @filterRules="setFilterRules" :conditions="cards"></filter-rules>
        </div>
        <div class="col-sm-12 mt-2" v-show="!(selectedRows.length === 0)">
          <b-button
            :disabled="selectedRows.length === 0"
            variant="outline-danger"
            class="push-left mb-2"
            @click="confirmDeleteMultiple"
          >
            <feather-icon icon="TrashIcon" /> {{ $t("Eliminar seleccionados") }}
          </b-button>
        </div>
      </div>
      <div v-show="!loading.first">
        <table-render
          id="table-rules"
          :loading="loading.rules"
          :schema="schema"
          :rows="rows"
          :actions="actions"
          :enabler="enableRule"
          :showCheckboxes="true"
          :selectedRows.sync="selectedRows"
        />
        <pagination :pagination="pagination" />
      </div>
      <div class="table-render-skeleton" v-if="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
    <modal-detail-rule ref="modalDetailRule" v-on:duplicate="getRules(true)" :cards="cards" :fxArray.sync="fxArray" :countries="countries"></modal-detail-rule>
  </div>
</template>

<script>
import TicketManagementService from '../../ticketManagement.service.js'
import ModalDetailRule from '../components/ModalDetailRule.vue'
import FilterRules from '../components/FilterRules.vue'
const ticketManagementService = new TicketManagementService()

export default {
  name: 'Rules',
  components: {ModalDetailRule, FilterRules},
  data() {
    return {
      schema: [],
      rows: [],
      originalRules: [],
      actions: [],
      loading: {
        first: true,
        rules: false
      },
      pagination: {
        page: 1,
        limit: 10,
        total: 0
      },
      selectedRows: [],
      value: { id: 2, text: 'b' },
      options: [{ id: 1, text: 'a' }, { id: 2, text: 'b' }],
      keyTableRender: 0,
      allSubjects: [],
      allStatuses: [],
      allSubStatuses: [],
      cards: [],
      countries: [],
      viewData: false,
      fxArray: {},
      filterRules: {}
    }
  },
  async mounted() {
    this.schema = [
      {label: 'ID', key: 'id', sortable: true},
      {label: 'Nombre', key: 'name', sortable: true},
      {label: 'Mensaje', key: 'message', sortable: true, class: ['wrappable-text']},
      {label: 'Nuevo asunto', key: 'ticket_subject_text', sortable: true},
      {label: 'Nuevo estado', key: 'ticket_sub_status_text', sortable: true},
      {label: 'Fecha creación', key: 'created_at_format', sortable: true, style: {width: '10%'}},
      {label: 'Tipo de regla', key: 'type_super', sortable: true, class: ['text-center'], useDangerHTML: true},
      {label: 'Tipo de regla', key: 'rule_type', sortable: true, class: ['text-center']},
      {label: 'Acciones', key: 'actions', sortable: false, class: ['text-center']}
    ]

    this.actions = [
      { action: id => this.enableRule(id), switchOwnId: 'activated', dualState: { on: 'Habilitado', off: 'Deshabilitado', iconOn: 'PowerIcon', iconOff: 'PowerIcon' } },
      { action: id => this.gotoPlatformDetail(id), icon: 'EyeIcon', color: 'info', text: 'Ver detalle' },
      { action: id => this.confirmDelete(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
    ]
    this.getAllInitialData()
  },
  watch: {
    'pagination.page'() {
      this.getRules(true)
    },
    'pagination.limit'() {
      this.getRules(true)
    }
  },
  methods: {
    gotoPlatformDetail (id) {
      // this.$router.push(`/modify-rule/${id}`)
      // window.open(`${environment.platformUrl}/detail-ticket-rules/${id}`, '_blank')
      this.$bvModal.show('modalDetailRule')
      this.$refs.modalDetailRule.dataModalDetail = this.rows.filter(rule => rule.id === id)[0]
      
    },
    setLoading(bool, text) {
      if (bool) {
        this.loading.rules = bool
      } else {
        setTimeout(() => this.loading.rules = bool, 500)
      }
    },
    setFilterRules(data) {
      this.filterRules = {...data}
      if (this.pagination.page !== 1) {
        this.pagination.page = 1
      } else {
        this.getRules(true)
      }
    },
    getRules(showLoading) {
      if (showLoading) this.setLoading(true, 'Mostrar cargando tabla')
      const queryParams = {
        ...this.pagination,
        ...this.filterRules
      }
      ticketManagementService.callService('getAllRules', queryParams)
        .then(response => {
          this.setDataToRows(response.data)
          this.pagination.total = response.total_registers
        }).catch(err => {
          this.$alert('Ocurrió un problema al cargar las reglas')
          console.error(err)
        })
        .finally(end => {
          if (showLoading) this.setLoading(false, 'Ocultar cargando tabla')
        })
    },
    getAllInitialData() {
      this.loading.first = true
      const arrServices = [
        { name: 'getAllRules', queryParams: { ...this.pagination } },
        { name: 'getTicketSubjects' },
        { name: 'getTicketStatuses' },
        {name: 'getSubStatuses'},
        {name: 'getConditions'},
        {name: 'getCountries'}
      ]
      ticketManagementService.callMultipleServices(arrServices, true)
        .then(response => {
          this.pagination.total = response.getAllRules.total_registers
          this.allSubjects = response.getTicketSubjects.data
          this.allStatuses = response.getTicketStatuses.data
          this.allSubStatuses = response.getSubStatuses.data
          this.originalRules = response.getAllRules.data
          this.cards = response.getConditions.data
          this.countries = [...response.getCountries.data]
          this.setDataToRows(response.getAllRules.data)
          this.viewData = true
        })
        .catch(err => {
          this.$alert('Ocurrió un problema al cargar las reglas')
          console.error(err)
        })
        .finally(end => {
          this.loading.first = false
          this.searchFx()
        })
    },
    searchFx() {
      if (this.cards.length > 0) {
        this.cards.forEach(card => {
          if (!!card.fx) {
            ticketManagementService.callService(card.fx, {limit:9999, page:1})
              .then(response => {
                this.fxArray[card.fx] = response.data
              })
              .catch(err => {
                console.error(err)
              })
          }
        })
      }
    },
    setDataToRows(rules) {
      this.rows = rules.map(rule => {
        const indexSubject = this.allSubjects.findIndex(({code}) => code === rule.ticket_type)
        const indexStatus = this.allStatuses.findIndex(({code}) => code === rule.ticket_status)
        const indexSubStatus = this.allSubStatuses.findIndex(({code}) => code === rule.ticket_sub_status)
        rule.created_at_format = this.$options.filters.moment(rule.created_at, 'YYYY-MM-DD')
        rule.ticket_subject_text = indexSubject === -1 ? 'Mantener asunto' : this.allSubjects[indexSubject].name
        rule.ticket_status_text = indexStatus === -1 ? 'Mantener estado' : this.allStatuses[indexStatus].complete_name
        rule.ticket_sub_status_text = indexSubStatus === -1 ? 'Mantener estado' : this.allSubStatuses[indexSubStatus].complete_name
        rule.enabler = rule.activated
        rule.type_super = rule.super ? '<span class="badge badge-warning badge-light">Super</span>' : '<span class="badge badge-dark badge-light">Normal</span>'
        rule.rule_type = rule.rule_type === 'automatic' ? 'Regla automática' : 'Regla programada'
        return rule
      })
    },
    openModalAddTicket(id) {
      this.selectedRows = []
      this.form = this.rows.filter(rule => rule.id === id)[0]
      this.dialogOpenAddAnswerTicket = true
    },
    confirmDeleteMultiple() {
      this.$yesno('¿Está seguro que desea eliminar estas reglas?', this.deleteMultipleRule)
    },
    deleteMultipleRule() {
      this.setLoading(true, 'Eliminando reglas')
      const arrayDeletion = this.selectedRows.map(id => {
        return { name: 'deleteRule', params: { id } }
      })
      ticketManagementService.callMultipleServices(arrayDeletion)
        .then(response => {
          this.$success('Las reglas seleccionados han sido eliminados correctamente')
        })
        .catch(err => {
          this.setLoading(false)
          this.$alert('No se pudieron completar todas las eliminaciones')
        })
        .finally(end => {
          this.selectedRows = []
          this.getRules(true)
        })
    },
    confirmDelete(id) {
      this.$yesno('¿Está seguro que desea eliminar esta regla?', (_) => this.deleteRule(id))
    },
    deleteRule(id) {
      this.setLoading(true, 'Eliminando regla')
      ticketManagementService.callService('deleteRule', null, { id })
        .then(response => {
          this.$success('La regla ha sido eliminada correctamente')
          this.rows = this.rows.filter(rule => parseInt(rule.id) !== parseInt(id))
        })
        .catch(err => {
          console.error(err)
          this.$alert('Ocurrió un problema al eliminar la regla')
        })
        .finally(end => {
          this.setLoading(false)
        })
    },
    enableRule(id) {
      const index = this.rows.findIndex(row => parseInt(row.id) === parseInt(id))
      const body = {...this.rows[index]}
      const queryParams = { 
        ...body,
        activated: !this.rows[index].activated 
      }
      this.rows[index].activated = null
      ticketManagementService.callService('updateRule', queryParams, { id })
        .then(response => {
          const newRow = {
            ...this.rows[index],
            activated: queryParams.activated
          }
          this.rows[index] = {...newRow}
          this.getRules(false)
        })
        .catch(err => {
          const ruleName = this.originalRules.filter(rule => parseInt(rule.id) === parseInt(id))[0].name
          this.$alert(`Ocurrió un problema al ${!this.rows[index].activated ? 'habilitar' : 'deshabilitar'} la regla "${ruleName}"`)
          console.error(err)
        })
    }
  }
}
</script>

<style scoped>
</style>
